/* Global Leaflet Map Styles */
.leaflet-container {
  width: 100%;
  height: 100%;
}

/* Draw Tool Styles */
.leaflet-draw-toolbar {
  margin-top: 10px !important;
}

.leaflet-draw-toolbar a {
  background-color: #fff !important;
  border: 1px solid #1b4584 !important;
  border-radius: 4px !important;
  margin-bottom: 5px;
  transition: all 0.2s ease;
  width: 30px !important;
  height: 30px !important;
}

.leaflet-draw-toolbar a:hover {
  background-color: #f3f4f6 !important;
}

.leaflet-draw-draw-circle {
  position: relative;
}

/* Override the default Leaflet draw sprite */
.leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-image: none !important;
  position: relative;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background-color: #1b4584;
  border-radius: 50%;
  border: 2px solid #1b4584;
}

/* Style the leaflet-draw action buttons to match MUI small button */
.leaflet-draw-actions a {
  background-color: #1976d2 !important; /* MUI primary color */
  color: #fff !important;
  padding: 3px 10px 5px !important; /* Smaller padding for small variant */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.8125rem !important; /* 13px - MUI small button font size */
  line-height: 1.75 !important;
  letter-spacing: 0.02857em !important;
  text-transform: uppercase !important;
  min-width: 48px !important; /* Smaller min-width for small variant */
  border-radius: 4px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.leaflet-draw-actions a:hover {
  background-color: #1565c0 !important; /* MUI primary dark */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.leaflet-draw-toolbar.leaflet-draw-toolbar-top {
  border: none;
  margin-left: 2px;
}

.leaflet-draw-actions {
  list-style: none;
  margin: 0;
  padding: 0;
  background: none !important;
  box-shadow: none !important;
}

.leaflet-draw-actions li {
  display: inline-block;
  margin: 4px !important;
}

/* Draw Tool Label Animation */
.draw-tool-label {
  animation: fadeIn 0.3s ease-out;
}

/* Map Container Styles */
.map-container {
  width: 100%;
  margin: 0 auto;
}

/* Map Page Styles */
.map-page {
  padding: 20px;
}

/* Error Message Styles */
.error-message {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  color: #991b1b;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 4px;
  animation: fadeIn 0.3s ease-in;
}

/* Button Styles */
.clear-button {
  padding: 8px 16px;
  background-color: #1b4584;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.2s;
}

.clear-button:hover {
  background-color: #153461;
}

/* Selected Zips Table Styles */
.selected-zips-container {
  width: 61.8%;
  margin: 3rem auto 20px;
  padding: 2rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.selected-zips-container > p {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
  font-size: 1.05rem;
  color: #374151;
  padding: 1rem 2rem;
}

.zip-codes-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 1.5rem;
}

.zip-codes-table th,
.zip-codes-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
  vertical-align: middle;
}

.zip-codes-table th {
  background-color: #f3f4f6;
  font-weight: 600;
  border-bottom: 2px solid #ddd;
  color: #4b5563;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
}

.table-image {
  width: auto;
  height: 40px;
  display: block;
  margin: 0 auto;
}

.city-name {
  font-size: 0.875rem;
  color: #666;
  margin-top: 2px;
}

.status-yes {
  color: #155724;
  background-color: #d4edda;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
}

/* Animation Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(2px);
  }
}

.shake-animation {
  animation: shake 0.5s ease-in-out infinite;
}

/* App Layout Styles */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.description-section {
  max-width: 1000px;
  margin: 0 auto 2rem auto;
  padding: 0 1.5rem;
  width: 100%;
}

.description-title {
  color: #1b4584;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.description-text {
  color: #374151;
  line-height: 1.6;
  font-size: 1.1rem;
  margin: 0;
}

.table-section {
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}

.app-footer {
  background-color: #f9fafb;
  padding: 1rem;
  text-align: center;
  color: #6b7280;
}

/* Responsive Design */
@media (max-width: 768px) {
  .selected-zips-container {
    width: 90%;
    padding: 1.5rem;
    margin-top: 2rem;
  }

  .selected-zips-container > p {
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.6;
  }

  .zip-codes-table th,
  .zip-codes-table td {
    padding: 0.75rem;
  }

  .table-image {
    height: 30px;
  }

  .leaflet-draw-toolbar {
    margin-top: 5px !important;
  }

  .draw-tool-label {
    font-size: 11px;
    padding: 4px 6px;
  }
}

/* Hide screen reader text */
.leaflet-draw-draw-circle .sr-only {
  display: none !important;
}

/* Position the MUI tooltip anchor */
.tooltip-anchor {
  position: absolute;
  left: 40px;
  top: 8px;
  width: 5px;
  height: 5px;
}

.mui-draw-tooltip {
  margin-left: 8px !important;
}

/* Wrapper for EditControl to work with MUI Tooltip */
.edit-control-wrapper {
  display: inline-block;
  position: relative;
  z-index: 1000;
}

.edit-control-wrapper .leaflet-draw-toolbar {
  margin-top: 10px !important;
}

/* Style the leaflet-draw tooltip to match MUI tooltip */
.leaflet-draw-tooltip {
  background-color: rgba(97, 97, 97, 0.92) !important;
  color: #fff !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  line-height: 1.4em !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  border: none !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  pointer-events: none !important; /* Ensure tooltip doesn't interfere with mouse events */
  will-change: transform !important; /* Optimize performance for transforms */
}

/* Remove the default leaflet tooltip arrow */
.leaflet-draw-tooltip:before {
  display: none !important;
}

/* Remove the animation that was causing positioning issues */
.leaflet-draw-tooltip {
  opacity: 1 !important;
}
